
.table-header .admin-table-pagination .next-page,
.table-header .admin-table-pagination .prev-page {

    float: right;
    background: none;
    border: 1px solid #dbd0d0;
    color: black;
    font-size: 16px !important;
    font-weight: bolder;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #dbd0d0;

}

.table-header .admin-table-pagination .prev-page {
    margin-right: 5px;
}

.table-header .admin-table-pagination .enter-page {
    width: 36px;
    display: inline-block;
    margin: 0 10px;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    height: calc(1.3em + 0.75rem + 2px);
}


.table-header .admin-table-pagination {
    width: 218px !important;
}

.mobilecodee input {
    padding-left: 45px;
}

.searchforms {
    position: relative;
}

.searchforms .crossbtn {
    position: absolute;
    top: 25px;
    right: 52px;
}

.searchforms input {

    padding-top: 6px;
    padding-bottom: 6px;
    position: relative;
    right: -1px;

}

.searchforms input:focus {
    border: 1px solid #ccc !important;
    outline: none !important;
}

.searchforms .btn-primary {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding-bottom: 5px;
    padding-top: 4px;
}


#clearfilterbtn {
    margin-top: 0 !important;
}


.table-header .admin-table-pagination .next-page:hover,
.table-header .admin-table-pagination .prev-page:hover {
    background: #f6f6f6 !important;
    color: blue;
}

// 
.admin-table-pagination {
    width: 270px;
    display: inline-block;
    float: right;
}

// .admin-table-pagination .next-page,
// .admin-table-pagination .prev-page {
//     float: right;
//     background: none;
//     border: none;
//     color: black;
//     font-size: 20px !important;
//     font-weight: bolder;
//     padding: 5px;
// }

.admin-table-pagination .enter-page {
    width: 70px;
    display: inline-block;
    margin: 0 10px;
    text-align: right;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    height: calc(1.5em + 0.75rem + 2px);
}

.iconButton.prev-page,
.iconButton.next-page {
    float: right;
}


.admin-list-table-search {
    width: 50%;
    float: left;
    display: -webkit-inline-box;
    position: relative;
}

.admin-list-table-search .crossbtn {
    position: absolute;
    top: 6px;
    left: 215px;
    z-index: 100;
}

.admin-list-table-search input[type="text"] {
    max-width: 247px;
    float: left;
    /* @media (max-width:576px)
    { max-width: calc(100vw - 78px);}   */
}

.admin-list-table-search input[type=text]::-ms-clear {
    display: none;
}

.admin-list-search-submit.fa-lg {
    display: inline-block;
    margin-left: 0px;
    vertical-align: bottom;
    background: #e9ecef;
    padding: 8.6px 8px;
    border: 1px solid #ced4da;
    /* &:hover {
      cursor: pointer;
      color: var(--mainColor);
    } */
}

.admin-list-search-submit.fa-lg:hover {
    cursor: pointer;
    color: var(--mainColor);
}

.dashboard .recent-sales .input-group .form-control {
    width: unset;
}

.search_input_box{
 margin-left: -.8rem;
}

// / Chrome, Safari, Edge, Opera /
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// / Firefox /
input[type=number] {
  -moz-appearance: textfield;
}