/* ======================home components ========================= */
.form_body{
    max-width: 430px !important;
    height: 90vh;
    position: relative;
  }
  
  .logo_style{
    width: 100%;
    bottom: 0;
    position: absolute;
    justify-content: center !important;
    display: flex !important;
  }
.h-8{
  height: 100vh;
}  
.h-100{
  height: 100%;
}
