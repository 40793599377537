.file-setting{
    position: relative;
      margin-top: 5px;
      margin-bottom: 5px;
      .custom-file-label{
        top: unset !important;
      }
    .custom-file-label::after
  {
      background-color: var(--mainColor) !important;
      color: #fff !important;
  }
  }