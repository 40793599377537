  .ciconClass{
    float: right;
    cursor: pointer;
    margin-top: -1.7rem;
    margin-right: .5rem;
  }

  .miconClass{
    float: right;
    cursor: pointer;
    margin-top: -1.7rem;
    margin-right: .5rem;
  }
