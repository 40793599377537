.passwordInput{
    width: 100%;
    padding: 12px 36px 12px 12px;
    box-sizing: border-box;
  }
  
  .myiconset { position:relative}

  .iconset {
    position:absolute;
    right: 25px;
    top: 50px;
  }

  .loginPassIcon{
    position:absolute;
    right: 19px;
    top: 45px;
  }