.sidebar{
    height: 100%;
    display: flex;
    align-items: center;
}
.hero {
    background-image: url("../../../../src/assets/img/hero-img.jpg");
    padding: 1rem 0;
    background-position: 40% center;
    background-size: cover;
  }

.h-100{
    height: 100%;
}
.hvh{
    height: 100vh !important;
}